import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
@Component({
  selector: "app-join-our-team",
  templateUrl: "./join-our-team.component.html",
  styleUrls: ["./join-our-team.component.scss"],
})
export class JoinOurTeamComponent implements OnInit {
  fGroup: FormGroup;
  currentIndex: number = 0;
  interval;

  team: string[] = [
    "기획",
    "디자인",
    "퍼블리싱",
    "프론트 엔드",
    "백 엔드",
    "마케팅",
    "운영서비스",
  ];
  tabs: string[] = ["BENEFIT", "WELFARE"];

  constructor(fb: FormBuilder, private http: HttpClient) {
    this.fGroup = fb.group({
      part: ["", Validators.required],
      name: ["", Validators.required],
      phone: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      attachType: ["file"],
      attachFile: [""],
      attachUrl: [""],
      contents: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.createInterval();
  }

  clear(i: number): void {
    clearInterval(this.interval);
    this.currentIndex = i;
  }
  //
  createInterval() {
    this.interval = setInterval(() => {
      if (this.currentIndex < this.tabs.length - 1) {
        this.currentIndex += 1;
      } else {
        this.currentIndex = 0;
      }
    }, 3000);
  }

  newInterval(): void {
    clearInterval(this.interval);
    this.createInterval();
  }
  submit(): void {
    if (this.fGroup.invalid) {
      alert("필수사항을 입력해주세요.");
      return;
    } else {
      this.http
        .post("http://awesomecode.co.kr:3100/api/recruit/create", {
          name: this.fGroup.get("name").value,
          phone: this.fGroup.get("phone").value,
          part: this.fGroup.get("part").value,
          email: this.fGroup.get("email").value,
          attach: this.fGroup.get("attachFile").value,
          contents: this.fGroup.get("contents").value,
        })
        .subscribe(
          (result) => {
            console.log(result);
            alert("Apply Success!");
          },
          (error) => {
            alert("Apply failed!");
          }
        );
    }
  }
}
