import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { AboutAwesomecodeComponent } from './about-awesomecode/about-awesomecode.component';
import { JoinOurTeamComponent } from './join-our-team/join-our-team.component';
import { LocationMapComponent } from './location-map/location-map.component';
import { OurServiceComponent } from './our-service/our-service.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'awesomecode';
  active = 'one';
  @ViewChild('one', { read: ElementRef }) one;
  @ViewChild('two', { read: ElementRef }) two;
  @ViewChild('three', { read: ElementRef }) three;
  @ViewChild('four', { read: ElementRef }) four;

  // isScroll: boolean = false;

  @HostListener("window:scroll", ["$event"]) onScrollEvent($event) {
    const pageYOffset = window.pageYOffset;
    const two = this.two.nativeElement.offsetTop;
    const threeTop = this.three.nativeElement.offsetTop;
    const four = this.four.nativeElement.offsetTop - 1000;

    if (0 <= pageYOffset && pageYOffset < two) {
      this.active = 'one';
      return;
    }

    if (two < pageYOffset && pageYOffset < threeTop) {
      this.active = 'two';
      return;
    }

    if (threeTop < pageYOffset && pageYOffset < four) {
      this.active = 'three';
      return;
    }

    this.active = 'four';
  }

  onNavigation(elName: string): void {
    this[elName].nativeElement.scrollIntoView({ behavior: 'smooth' });
  }


}
