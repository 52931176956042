<div id="header">
  <div class="header-inner flexbox justify-content-space-between align-items-center">
    <h1 id="logo" (click)="goTop()" title="AwesomeCode">AwesomeCode</h1>
    <button class="btn-mobile" (click)="isNavShow()" [ngClass]="{'btn-close':isNav}">
      <mat-icon>{{ isNav ? 'close' : 'menu'}}</mat-icon>
    </button>
    <div class="m-nav-back" *ngIf="isNav"></div>
    <ul class="nav flexbox align-items-center" [ngClass]="{'m-nav' : isNav}">
      <li [ngClass]="{active : active === 'one'}" title="어썸코드?" (click)="scrollTo('one', $event)">어썸코드?<span
          class="info-eng">About
          Awesomecode</span></li>
      <li [ngClass]="{active : active === 'two'}" title="어썸한 서비스" (click)="scrollTo('two', $event)">어썸한 서비스<span
          class="info-eng">Our Service</span></li>
      <li [ngClass]="{active : active === 'three'}" title="이제 곧 어썸인" (click)="scrollTo('three', $event)">이제 곧 어썸인<span
          class="info-eng">Join our team</span>
      </li>
      <li [ngClass]="{active : active === 'four'}" title="똑똑! 어서오세요." (click)="scrollTo('four', $event)">똑똑! 어서오세요.<span
          class="info-eng">Location Map</span>
      </li>
      <!-- <li routerLink="/mobilesample" title="모바일 샘플">모바일 샘플<span
        class="info-eng">Location Map</span>
      </li> -->
    </ul>
  </div>
  <div class="cont-paging inner">
    <ol>
      <li [ngClass]="{active:  active === 'one'}" class="active" title="어썸코드?" (click)="scrollTo('one', $event)">어썸코드?
      </li>
      <li [ngClass]="{active:  active === 'two'}" title="어썸한 서비스" (click)="scrollTo('two', $event)">어썸한 서비스</li>
      <li [ngClass]="{active:  active === 'three'}" title="이제 곧 어썸인" (click)="scrollTo('three', $event)">이제 곧 어썸인</li>
      <li [ngClass]="{active:  active === 'four'}" title="똑똑! 어서오세요." (click)="scrollTo('four', $event)">똑똑! 어서오세요.</li>
      <!-- <li routerLink="/mobilesample" title="모바일 샘플" >모바일 샘플</li> -->
    </ol>
  </div>
</div>
