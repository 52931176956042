<div id="wrap">
  <app-header (nav)="onNavigation($event)" [active]="active"></app-header>
  <div class="contents">
    <app-visual></app-visual>
    <app-about-awesomecode #one></app-about-awesomecode>
    <app-our-service #two></app-our-service>
    <app-join-our-team #three></app-join-our-team>
    <app-location-map #four></app-location-map>
    <!-- <app-hammer-card></app-hammer-card> -->
    <app-footer></app-footer>
  </div>
</div>
<router-outlet></router-outlet>
