import { Component, ElementRef, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  goTop() {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }
  constructor(private elementRef: ElementRef) { }

  ngOnInit(): void {
  }

}
