
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-awesomecode',
  templateUrl: './about-awesomecode.component.html',
  styleUrls: ['./about-awesomecode.component.scss'],
  animations: [

  ]
})
export class AboutAwesomecodeComponent implements OnInit {
  active = 1;
  interval;


  awesomeinfo = [
    {
      title: 'AWESOMECODE?',
      content: 'Awesome Code(어썸코드)는',
      content2: '멋진ㆍ놀라운ㆍ굉장한이라는 의미의 Awesome과',
      content3: '개발 프로그래밍을 의미하는 Code를 더하여',
      content4: '멋진 개발ㆍ놀라운 프로그래밍을 한다는 뜻으로',
      content5: '커머스산업에 획을 긋는 개발회사로 발돋움 할 것입니다.',
    },
    {
      title: 'CREATIVE & FLEXIBLE',
      content: '어썸코드인은 자유롭지만',
      content2: '다른 사람을 배려하며 책임감이 강합니다.',
      content3: '창의적이고 개성이 강하나 부서간 협업을 자연스럽게',
      content4: '이끌어내는 유연함을 겸비한',
      content5: '재능인들 입니다.',
    },
    {
      title: 'PEOPLE – THAT’S WHAT WE CARE',
      content: '사람은 누구나 실수하고 실패할 수 있습니다.',
      content2: '실패를 두려워하지 않고 다시금 도전하며',
      content3: '끊임없는 도전을 통해 발돋움하여 성장하는',
      content4: '역동적이고 열정적인 인재들이 모여있는 곳',
      content5: '그곳이 바로 어썸코드입니다.',
    },
  ];


  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  // startAnimation() {
  //   this.interval = setInterval(() => {
  //     this.active = (this.active + 1) % 6 || 6;
  //     this.cdr.markForCheck();
  //   }, 5000);
  // }
  // onSwipe(event) {
  //   clearInterval(this.interval);
  //   console.log('hh');
  //   // 왼쪽으로 밀었을 때
  //   if (event.direction === 2) {
  //     this.active = (this.active + 1) % 6 || 6;
  //     console.log('zz');

  //   }
  //   // 오른쪽으로 밀었을 때
  //   if (event.direction === 4) {
  //     this.active = (this.active - 1) % 6 || 6;
  //     console.log('gg');
  //   }

  //   this.startAnimation();
  // }
  // getIndex(value: number) {
  //   return (this.mod(value - this.active, 6) + 1 || 7) - 1;
  // }

  // mod(n, m) {
  //   return ((n % m) + m) % m;
  // }


}
