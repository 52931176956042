<div class="footer">
  <div class="in">
    <div class="btn-fix">
  <aside></aside>
  <button class="move-top" (click)="goTop()">Top</button></div>
  <div class="inner">
    <p class="copyright">대표 : 이광수  사업자번호 : 829-81-01845  &copy; <b>awesomecode. All rights reserved.</b></p>
  </div>
</div>
</div>
