import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-visual',
  templateUrl: './visual.component.html',
  styleUrls: ['./visual.component.scss']
})
export class VisualComponent implements OnInit {
  currentIndex: number = 0;
  interval;

  get isPhone(): boolean {
    return window.innerWidth < 641;
  }

  get imgType(): string {
    return this.isPhone ? 'image_mobile' : 'image';
  }

  visual = [
    {
      image: "/assets/imgs/main-visual-01.jpg",
      image_mobile: "/assets/imgs/m-visual-01.jpg",
      title: "Be Responsible",
      info: "커머스의 무한한 가능성을 믿고 끊임없이 도전합니다."
    },
    {
      image: "/assets/imgs/main-visual-02.jpg",
      image_mobile: "/assets/imgs/m-visual-02.jpg",
      title: "Seize the day",
      info: "게임처럼 즐겁고 자유롭게 일합니다."
    },

    {
      image: "/assets/imgs/main-visual-03.jpg",
      image_mobile: "/assets/imgs/m-visual-03.jpg",
      title: "Work Life Balance",
      info: "자기계발을 지향하며 수평적인 인간관계로 다가갑니다."
    },

    {
      image: "/assets/imgs/main-visual-04.jpg",
      image_mobile: "/assets/imgs/m-visual-04.jpg",
      title: "Take the lead",
      info: "건전하고 건강한, 즐거운 커머스 문화를 선도합니다."
    },

    {
      image: "/assets/imgs/main-visual-05.jpg",
      image_mobile: "/assets/imgs/m-visual-05.jpg",
      title: "You are the one",
      info: "주인의식을 가지고 시작한 일은 반드시 끝을 맺는 책임감을 가집니다."
    },

    {
      image: "/assets/imgs/main-visual-06.jpg",
      image_mobile: "/assets/imgs/m-visual-06.jpg",
      title: "Having Fun",
      info: "즐거움이란 필연적으로 스스로 만드는 것"
    }
  ];

  constructor() { }

  ngOnInit(): void {
    this.createInterval();
  }


  clear(index: number): void {
    clearInterval(this.interval);
    this.currentIndex = index;
  }

  newInterval(): void {
    clearInterval(this.interval);
    this.createInterval();
  }

  createInterval() {
    this.interval = setInterval(() => {
      if (this.currentIndex < this.visual.length - 1) {
        this.currentIndex += 1;
      } else {
        this.currentIndex = 0;
      }
    }, 3000);
  }

}
