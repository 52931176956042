<div class="visual" (mouseout)="newInterval()">
  <div class="in">
    <div class="inner">
      <div>
        <img [src]="visual[currentIndex][imgType]" alt="AwesomeCode">
        <div class="slogan">
          <h2 class="visual-title font-arial">{{visual[currentIndex].title}}</h2>
          <p class="visual-info">{{visual[currentIndex].info}}</p>
        </div>
      </div>
      <div class="visual-paging">
        <ol class="">
          <li (click)="clear(i)" *ngFor="let i of [0,1,2,3,4,5]" [ngClass]="{active: i === currentIndex}" class="">
            0{{i + 1}}</li>
        </ol>
      </div>
    </div>
  </div>
</div>

<!-- <div class="visual" (mouseout)="newInterval()" (swipeLeft)="onSwipeLeft($event)" (swipeRight)="onSwipeRight($event)">
  <div class="in">
    <div class="inner">
      <div class="testDiv" [@slideAnimation]="animationState" (@slideAnimation.done)="resetAnimationState()"
        [ngClass]="{on : currentIndex}">
        <img [src]="visual[currentIndex].image" alt="AwesomeCode">
        <div class="slogan">
          <h2 class="visual-title font-arial">{{visual[currentIndex].title}}</h2>
          <p class="visual-info">{{visual[currentIndex].info}}</p>
        </div>
      </div>
      <div class="visual-paging">
        <ol class="">
          <li (click)="clear(i)" *ngFor="let i of [0,1,2,3,4,5]" [ngClass]="{active: i === currentIndex}" class="">
            0{{i + 1}}</li>
        </ol>
      </div>
    </div>
  </div>
</div> -->
