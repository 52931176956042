<div class="our-service" (mouseout)="newInterval()">
  <div class="in">
    <div class="inner">
      <h2 class="main-title white">Our Service</h2>
      <ul class="tabs flexbox">
        <!--li (click)="clear(i)" *ngFor="let tab of tabs; let i = index" [ngClass]="{active : i === currentIndex}">{{ tab}}</li-->
        <!--li class="currentIndex">Metaverse Mining Game</li-->
        <li class="currentIndex">Online Shopping Mall</li>
      </ul>
      <div class="cont sports-book">
        <!--p class="info-text">마이닝은 게임과 교육을 접목한 본격 게이미피케션(Gamification) 장르로서 자신만의 고유한 생산성 NFT 아이템을 제작하여 
          <br />각 아이템의 구동원리와 과학적 지식을 게임을 즐기며 재밌게 습득해 가는 가상의 메타버스 게임입니다.</p-->
        <p class="info-text">Nearbuyshop 온라인 쇼핑몰은 판매자와 소비자와의 유통단계를 획기적으로 간소화 시켜 스마트한 온라인 커머스를 실현 시키고 
          <br />그 혜택을 판매자와 소비자에게 다시 돌려주는 WEB 3.0 온라인 커머스의 선두 주자가 되겠습니다.</p>
        <div class="info-group flexbox justify-content-center">
          <!--dl>
            <dt>SportsBook Maker</dt>
            <dd>누구나 SportsBook을 만들 수 있습니다.
              <br />자신만의 배당과 운영방식으로 누구나
              <br />Bwin, Bet365 수준의 스포츠북을 오픈하고 이를 통하여 <br />스포츠북 경영 시뮬레이션을 경험할 수 있습니다.
              <br />게임 내 광고 역시 가능합니다.</dd>
          </dl>
          <dl>
            <dt>Analyst</dt>
            <dd>누구나 분석가가 될 수 있습니다.
              <br />자신만의 승부예측 노하우를 분석글로 만들어 자신의 <br />팔로워들에게 공유할 수 있고 이에 대한 보상으로 아이템 및 <br />팬덤을 확보할 수 있습니다.</dd>
          </dl>
          <dl>
            <dt>Bettor</dt>
            <dd>누구나 베터가 될 수 있습니다.
              <br />베팅을 통하여 승률, 수익률 등의 랭커가 되어 게임내에서 <br />베터로서 스타가 될 수 있습니다. 베터만의 특권인 6개의 <br />인피니티 스톤을 모두 모아 잭팟을 터트려
              보세요!</dd>
          </dl-->
          <img src="assets/imgs/nearbuyshop.png" />
        </div>
      </div>
      <!--div class="cont social-casino" *ngIf="currentIndex === 1">
        <p class="info-text">건전하고 건강한, 즐거운 게임 문화를 선도합니다.
          <br />즐거움이란 필연적으로 스스로 만드는것, 게임의 무한한 가능성을 믿고 끊임없이 도전합니다.</p>
        <div class="info-group flexbox justify-content-center">
          <dl>
            <dt>Baccarat</dt>
            <dd><img src="assets/imgs/service-baccarat.png" alt="Baccarat"></dd>
          </dl>
          <dl>
            <dt>Blackjack</dt>
            <dd><img src="assets/imgs/service-blackjack.png" alt="blackjack"></dd>
          </dl>
          <dl>
            <dt>Mahjong</dt>
            <dd><img src="assets/imgs/service-mahjong.png" alt="mahjong"></dd>
          </dl>
          <dl>
            <dt>Hold'em</dt>
            <dd><img src="assets/imgs/service-holdem.png" alt="holdem"></dd>
          </dl>
        </div>
      </div-->
    </div>
  </div>
</div>
