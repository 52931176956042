import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

const routes: Routes = [
  /* 
  { path: "", pathMatch: "full", redirectTo: "" },
  {
    path: "mobilesample",
    loadChildren: () =>
      import("./spo-mobile-sample/spo-mobile-sample.module").then(
        (m) => m.SpoMobileSampleModule
      ),
  },
  {
    path: "register",
    loadChildren: () =>
      import("./views/register/register.module").then((m) => m.RegisterModule),
  },
  {
    path: "**",
    loadChildren: () =>
      import("./views/login/login.module").then((m) => m.LoginModule),
    // canActivate: [AuthGuard],
  }, */
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
