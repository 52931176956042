import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  currentIndex: number = 0;

  isNav: boolean = false;
  @Input() active: string;

  @Output() nav = new EventEmitter();

  constructor() { }

  scrollTo(elName: string, event: Event): void {
    event.stopPropagation();
    this.active = elName;
    this.nav.emit(elName);
    this.isNav = false;
  }
  isNavShow(): void { this.isNav = !this.isNav; }

  ngOnInit(): void {
  }

  goTop() {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }

}
