import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-service',
  templateUrl: './our-service.component.html',
  styleUrls: ['./our-service.component.scss']
})
export class OurServiceComponent implements OnInit {
currentIndex: number = 0;
interval;
tabs = ['SPORT BOOK', 'SOCIAL CASINO'];

get isMobile(): boolean {
  return window.innerWidth < 1201;
}

  constructor() { }

  ngOnInit(): void {
      this.createInterval();
  }

  clear(i: number): void {
    clearInterval(this.interval);
    this.currentIndex = i;
  }

  createInterval() {
    if (!this.isMobile) {
      this.interval = setInterval(() => {
        this.currentIndex = this.currentIndex === 1 ? 0 : 1;
      }, 3000);
    }
  }

  newInterval(): void {
    clearInterval(this.interval);
    this.createInterval();
  }

}
