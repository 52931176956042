<mat-card class="example-card" [@cardAnimator]="animationState" (@cardAnimator.done)="resetAnimationState()"
  (swipe)="startAnimation('swing')">
  <mat-card-content>
    <img src="asset/imgs/ico-apply.png">
    <p>testt e s t rrdfd j flksj fsdjfk jaklf</p>
    <div class="test-box-wrap">
      <span style="background:yellow; padding:10px;" (tap)="startAnimation('slide')">open</span>
      <div class="test-box">
      </div>
    </div>
  </mat-card-content>
  <!-- <mat-card-actions>
    <button mat-raised-button (tap)="startAnimation('slide')">SLIDE</button>
    <button mat-raised-button (tap)="startAnimation('swing')">SWING</button>

    <h5>Custom Events</h5>
    <button hammertime mat-raised-button color="accent" (doubleTap)="startAnimation('swing')"></button>
  </mat-card-actions> -->
</mat-card>
