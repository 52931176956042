import { Directive, HostListener, Output, } from '@angular/core';
import { EventEmitter } from 'events';

@Directive({
  selector: '[appHammertime]'
})
export class HammertimeDirective {

  @Output() doubleTap = new EventEmitter();
  @Output() tripleTap = new EventEmitter();

  constructor() { }

  @HostListener('tab', ['$event'])
  onTap(e) {
    if (e.tabCont === 2) {
      this.doubleTap.emit(e)
    }
    if (e.tabCont === 3) {
      this.tripleTap.emit(e)
    }

  }

}
