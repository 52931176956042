<div class="location-map">
  <div class="inner">
    <h2 class="main-title black">Location Map</h2>
    <div class="map"><img src="assets/imgs/location2.png" alt="찾아오시는 길"></div>
    <ul class="location-info flexbox cont-inner">
      <li>
        <h5>Our office</h5>
        <p class="address">서울특별시 금천구 디지털로 121(에이스가산타워) 1802호
          <br />어썸코드</p>
        <ol class="contact">
          <li><span>Tel</span>02.3016.5486</li>
          <li><span>Fax</span>02.3016.5487</li>
          <li><span>Email</span><a href="mailto:help@awesomecode.co.kr">help@awesomecode.co.kr</a></li>
        </ol>
      </li>
      <li>
        <h5>How to come</h5>
        <div class="subway flexbox">
          <h6>SUBWAY</h6>
          <div>
            <p class="flexbox"><span>7호선</span>가산디지털단지역 5번 출구 300m 도보 후
              <br />우측 횡단보도 건너 70m 직진
            </p>
          </div>
        </div>
        <div class="flexbox">
          <h6>BUS</h6>
          <div>
            <p class="flexbox"><span>간선</span>21번 / 503번 / 504번 / 571번 / 652번</p>
            <p class="flexbox"><span>마을</span>금천 05번</p>
            <p class="flexbox"><span>지선</span>5536번 / 5714번</p>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>
