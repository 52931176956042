import {
  BrowserModule,
  HammerModule,
  HammerGestureConfig,
  HAMMER_GESTURE_CONFIG,
} from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AboutAwesomecodeComponent } from "./about-awesomecode/about-awesomecode.component";
import { OurServiceComponent } from "./our-service/our-service.component";
import { JoinOurTeamComponent } from "./join-our-team/join-our-team.component";
import { FooterComponent } from "./footer/footer.component";
import { VisualComponent } from "./visual/visual.component";
import { LocationMapComponent } from "./location-map/location-map.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatSelectModule } from "@angular/material/select";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { HeaderComponent } from "./header/header.component";
import { MatCardModule } from "@angular/material/card";
import * as Hammer from "hammerjs";
import { HammerCardComponent } from "./hammer-card/hammer-card.component";
import { HammertimeDirective } from "./hammertime.directive";
import { MatIconModule } from "@angular/material/icon";
import { httpsInterceptor } from "./interceptor/http.interceptor";

export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    // override hammerjs default configuration
    swipe: { direction: Hammer.DIRECTION_ALL },
  };
}

@NgModule({
  declarations: [
    AppComponent,
    AboutAwesomecodeComponent,
    OurServiceComponent,
    JoinOurTeamComponent,
    FooterComponent,
    VisualComponent,
    LocationMapComponent,
    HeaderComponent,
    HammerCardComponent,
    HammertimeDirective,
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    ReactiveFormsModule,
    HammerModule,
    MatIconModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
    { provide: HTTP_INTERCEPTORS, useClass: httpsInterceptor, multi: true },
  ],
})
export class AppModule {}
