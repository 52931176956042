<div class="about-awesomecode">
  <h2 class="main-title black">About Awesomecode</h2>
  <div class="inner flexbox justify-content-center">
    <dl class="sec" *ngFor="let box of awesomeinfo; let i = index">
      <dt>{{box.title}}</dt>
      <dd>{{box.content}}
        <br />{{box.content2}}
        <br />{{box.content3}}
        <br />{{box.content4}}
        <br />{{box.content5}}</dd>
    </dl>
    <!-- <dl class="sec-01">
      <dt>AWESOMECODE?</dt>
      <dd>Awesome Code(어썸코드)는
        <br />멋진ㆍ놀라운ㆍ굉장한이라는 의미의 Awesome과
        <br />개발 프로그래밍을 의미하는 Code를 더하여
        <br />멋진 개발ㆍ놀라운 프로그래밍을 한다는 뜻으로
        <br />게임산업에 획을 긋는 개발회사로 발돋움 할 것입니다.</dd>
    </dl>
    <dl class="sec-02">
      <dt>CREATIVE &amp; FLEXIBLE</dt>
      <dd>어썸코드인은 자유롭지만
        <br />다른 사람을 배려하며 책임감이 강합니다.
        <br />창의적이고 개성이 강하나 부서간 협업을 자연스럽게
        <br />이끌어내는 유연함을 겸비한
        <br />재능인들 입니다.</dd>
    </dl>
    <dl class="sec-03" >
      <dt>PEOPLE – THAT’S WHAT WE CARE</dt>
      <dd>사람은 누구나 실수하고 실패할 수 있습니다.
        <br />실패를 두려워하지 않고 다시금 도전하며,
        <br />끊임없는 도전을 통해 발돋움하여 성장하는
        <br />역동적이고 열정적인 인재들이 모여있는 곳
        <br />그곳이 바로 어썸코드입니다.</dd>
    </dl> -->
  </div>
</div>
