<div class="join-our-team">
  <div class="inner">
    <h2 class="main-title black">Join Our Team</h2>
    <p class="add-text">AWESOMECODE 와 미래를 함께하실 분을 기다립니다. 지금 도전하세요.</p>
    <div class="info-block">
      <div class="cont-inner flexbox">
        <div class="innovation">
          <span class="summary">어썸코드, 혁신을 꿈꾸다</span>
          <h5 class="font-arvo">Innovation</h5>
          <p>남들과는 뭔가 다른 혁신을 추구하는 소셜 커머스
            <br />커머스 문화의 대중화와 혁신을 꿈꾸는</p>
        </div>
        <div class="flexbox flex-direction-column">
          <div class="passion">
            <span class="summary">어썸코드, 열정을 품다</span>
            <h5 class="font-arvo">Passion &amp; Passion</h5>
            <p>언제나 최고가 되고자 노력하며
              <br />끝까지 포기하지 않는 열정이 가슴에 가득한</p>
          </div>
          <div class="enjoy">
            <div>
              <span class="summary">어썸코드, 즐거움의 공간이되다</span>
              <h5 class="font-arvo">Fun,&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <br />Enjoy!</h5>
              <p>매 순간을 즐길 수 있는
                <br />긍정적인 마인드로 가득찬</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="info-box">
      <ol class="cont-inner flexbox flex-wrap">
        <li class="front-end">
          <h6>프론트 엔드<b>Front End</b></h6>
          <span>For Fast and Interactive</span>
          <p>Angular 8+ / Material Design / Native Script / Electron / Ionic / ES6+</p>
        </li>
        <li class="back-end">
          <h6>백 엔드<b>Back End</b></h6>
          <span>For Performance</span>
          <p>Mongo DB / Docker / Node JS</p>
        </li>
        <li class="server">
          <h6>서버<b>Server</b></h6>
          <span>For Secure and Stable</span>
          <p>AWS / Linux</p>
        </li>
        <li class="service">
          <h6>서비스<b>Service</b></h6>
          <span>For Multi Devices</span>
          <p>Windows / Mac / Android / Html 5 / IOS</p>
        </li>
      </ol>
    </div>
    <div class="info-step cont-inner flexbox justify-content-center">
      <div class="step-01">
        <span>step 1</span>
        <figure class="image flexbox justify-content-center align-items-center"><img src="assets/imgs/ico-apply.png"
            alt="지원 접수"></figure>
        <p>지원 접수</p>
      </div>
      <div class="step-02">
        <span>step 2</span>
        <figure class="image flexbox justify-content-center align-items-center"><img src="assets/imgs/ico-paper.png"
            alt="서류 전형"></figure>
        <p>서류 전형</p>
      </div>
      <div class="step-03">
        <span>step 3</span>
        <figure class="image flexbox justify-content-center align-items-center"><img
            src="assets/imgs/ico-interview.png" alt="인터뷰"></figure>
        <p>인터뷰</p>
      </div>
      <div class="step-04">
        <span>step 4</span>
        <figure class="image flexbox justify-content-center align-items-center"><img src="assets/imgs/ico-thumb.png"
            alt="최종 합격"></figure>
        <p>최종 합격</p>
      </div>
    </div>
    <div class="inqury-form cont-inner">
      <h4 class="font-arial">Recruit</h4>
      <div class="form-wrap">
        <form [formGroup]="fGroup">
          <div class="input-wrap">
            <div class="input input-half input-select">
              <mat-form-field class="example-full-width">
                <mat-select formControlName="part">
                  <mat-option value="">지원분야를 선택하세요.</mat-option>
                  <mat-option *ngFor="let apply of team" [value]="apply">{{apply}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="flexbox">
              <div class="input input-half input-name">
                <mat-form-field class="example-full-width">
                  <mat-label>이름</mat-label>
                  <input matInput placeholder="" value="" formControlName="name">
                  <span class="icon"><img src="assets/imgs/ico-user.png" alt="" /></span>
                </mat-form-field>
              </div>
              <div class="input input-half input-phone">
                <mat-form-field class="example-full-width">
                  <mat-label>핸드폰</mat-label>
                  <input matInput type="number" placeholder="" value="" formControlName="phone">
                  <span class="icon"><img src="assets/imgs/ico-phone.png" alt="" /></span>
                </mat-form-field>
              </div>
            </div>
            <div class="flexbox">
              <div class="input input-half input-email">
                <mat-form-field class="example-full-width">
                  <mat-label>이메일 주소</mat-label>
                  <input matInput type="text" placeholder="" value="" formControlName="email">
                  <span class="icon"><img src="assets/imgs/ico-mail.png" alt="" /></span>
                </mat-form-field>
              </div>
              <div class="input input-half input-file flexbox align-items-baseline">
                <div class="select-list">
                  <mat-form-field class="example-full-width select-field">
                    <mat-select formControlName="attachType">
                      <mat-option value="file">파일첨부</mat-option>
                      <mat-option value="url">URL</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <p>
                  <span class="attach-file flexbox" *ngIf="fGroup.get('attachType').value === 'file'; else url">
                    <input type="file" placeholder="" value="">
                    <span class="icon"><img src="assets/imgs/ico-file.png" alt="" /></span>
                  </span>
                  <ng-template #url>
                    <mat-form-field class="example-full-width">
                      <input matInput type="text" placeholder="" formControlName="attachUrl">
                      <span class="icon"><img src="assets/imgs/ico-file.png" alt="" /></span>
                    </mat-form-field>
                  </ng-template>
                </p>
              </div>
            </div>
            <div class="input input-content">
              <mat-form-field class="example-full-width">
                <mat-label>내용 입력</mat-label>
                <input formControlName="contents" matInput type="text" placeholder="" value="">
              </mat-form-field>
            </div>
          </div>
          <button (click)="submit()" class="btn-apply font-arial" [ngClass]="{active: fGroup.valid}">Apply to awesomecode</button>
        </form>
      </div>
    </div>
  </div>
</div>
<div class="staff" (mouseout)="newInterval()">
  <div class="in">
    <div class="inner">
      <h2 class="main-title white">For Awesomecode Staff</h2>
      <ul class="tabs flexbox">
        <li (click)="clear(i)" *ngFor="let tab of tabs; let i = index" [ngClass]="{active : i === currentIndex}">{{ tab}}</li>
      </ul>
      <div class="cont benefit" *ngIf="currentIndex === 0">
        <p class="info-text">어썸코드의 가족이 되시면 아래와 같은 환경에서 다양함을 함께 하실 수 있습니다.</p>
        <div class="info-box flexbox justify-content-center">
          <dl>
            <dt>
              <span>Collaboration</span>
              <img src="assets/imgs/ico-block.png" alt="Collaboration" /></dt>
            <dd>경험이 많은 시니어 개발자, 열정이 있는 주니어
              <br />개발자들이 함께 연구하고 협업하여
              <br />개발할 수 있는 기회를 제공합니다.</dd>
          </dl>
          <dl>
            <dt>
              <span>New Generation</span>
              <img src="assets/imgs/ico-enter.png" alt="New Generation" /></dt>
            <dd>기존에 사용되고 있는 프로그램 언어에 대한 <br />이해도를 기반으로 하여 차세대 플랫폼으로 <br />프로젝트를 개발할 기회를 GET하세요!</dd>
          </dl>
          <dl>
            <dt>
              <span>Be Trendy</span>
              <img src="assets/imgs/ico-trendy.png" alt="Be Trendy" /></dt>
            <dd>새로운 프로그램 언어들이 계속 개발되고 있으며 <br />어썸코드에서는 트렌드에 맞는 개발 방법을 <br />체계적으로 학습할 수 있습니다.</dd>
          </dl>
          <dl>
            <dt>
              <span>Cooperation</span>
              <img src="assets/imgs/ico-cooperation.png" alt="Cooperation" /></dt>
            <dd>기획, 아트, 퍼블리셔, 개발 등 부서간 균형적 협업 <br />시스템을 구축하여 토의하고 토론하며
              <br />프로젝트를 완성할 수 있습니다.</dd>
          </dl>
        </div>
      </div>
      <div class="cont welfare" *ngIf="currentIndex === 1">
        <p class="info-text">어썸코드의 가족이 되시면 아래와 같은 환경에서 다양함을 함께 하실 수 있습니다.</p>
        <div class="info-figure flexbox justify-content-center">
          <figure class="figu-01">
            <figcaption>선택적 복리후생제도</figcaption>
          </figure>
          <figure class="figu-02">
            <figcaption>야근시 택시비 지원</figcaption>
          </figure>
          <figure class="figu-03">
            <figcaption>중식 제공</figcaption>
          </figure>
          <figure class="figu-04">
            <figcaption>경조사 지원</figcaption>
          </figure>
        </div>
      </div>
    </div>
  </div>
</div>
